import "../styles/pages/404.scss";

import Layout from "../components/Layout";
import { Link } from "gatsby";
import React from "react";
import SEO from "../components/SEO";

/**
 *
 */
function Error404() {
  return (
    <Layout theme="dark">
      <SEO />
      <article className="Error404">
        <h1 className="Error404-heading">Error 404</h1>
        <Link className="Error404-link" to="/">
          Back to Home
        </Link>
      </article>
    </Layout>
  );
}

export default Error404;
